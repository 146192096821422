export const COUNTRY_LIST_REQUEST = 'COUNTRY_LIST_REQUEST';
export const COUNTRY_LIST_SUCCESS = 'COUNTRY_LIST_SUCCESS';
export const COUNTRY_LIST_FAIL = 'COUNTRY_LIST_FAIL';
export const COUNTRY_LIST_RESET = 'COUNTRY_LIST_RESET';

export const STATE_LIST_REQUEST = 'STATE_LIST_REQUEST';
export const STATE_LIST_SUCCESS = 'STATE_LIST_SUCCESS';
export const STATE_LIST_FAIL = 'STATE_LIST_FAIL';
export const STATE_LIST_RESET = 'STATE_LIST_RESET';

export const CITY_LIST_REQUEST = 'CITY_LIST_REQUEST';
export const CITY_LIST_SUCCESS = 'CITY_LIST_SUCCESS';
export const CITY_LIST_FAIL = 'CITY_LIST_FAIL';
export const CITY_LIST_RESET = 'CITY_LIST_RESET';
