export const WALLET_NONCE_REQUEST = 'WALLET_NONCE_REQUEST';
export const WALLET_NONCE_SUCCESS = ' WALLET_NONCE_SUCCESS';
export const WALLET_NONCE_FAIL = ' WALLET_NONCE_FAIL';

export const WALLET_VERIFY_REQUEST = 'WALLET_VERIFY_REQUEST';
export const WALLET_VERIFY_SUCCESS = ' WALLET_VERIFY_SUCCESS';
export const WALLET_VERIFY_FAIL = ' WALLET_VERIFY_FAIL';
export const WALLET_VERIFY_RESET = 'WALLET_VERIFY_RESET';

export const WALLET_INFORMATION_REQUEST = 'WALLET_INFORMATION_REQUEST';
export const WALLET_INFORMATION_SUCCESS = ' WALLET_INFORMATION_SUCCESS';
export const WALLET_INFORMATION_FAIL = ' WALLET_INFORMATION_FAIL';
export const WALLET_INFORMATION_RESET = ' WALLET_INFORMATION_RESET';

export const FAMILY_NETWORK_REQUEST = 'FAMILY_NETWORK_REQUEST';
export const FAMILY_NETWORK_SUCCESS = 'FAMILY_NETWORK_SUCCESS';
export const FAMILY_NETWORK_FAIL = 'FAMILY_NETWORK_FAIL';

export const USER_SIGNATURES_REQUEST = 'USER_SIGNATURES_REQUEST';
export const USER_SIGNATURES_SUCCESS = 'USER_SIGNATURES_SUCCESS';
export const USER_SIGNATURES_FAIL = 'USER_SIGNATURES_FAIL';

export const ALL_SIGNATURES_REQUEST = 'ALL_SIGNATURES_REQUEST';
export const ALL_SIGNATURES_SUCCESS = 'ALL_SIGNATURES_SUCCESS';
export const ALL_SIGNATURES_FAIL = 'ALL_SIGNATURES_FAIL';

export const CONTRIBUTION_CREATE_REQUEST = 'CONTRIBUTION_CREATE_REQUEST';
export const CONTRIBUTION_CREATE_SUCCESS = 'CONTRIBUTION_CREATE_SUCCESS';
export const CONTRIBUTION_CREATE_FAIL = 'CONTRIBUTION_CREATE_FAIL';
export const CONTRIBUTION_CREATE_RESET = 'CONTRIBUTION_CREATE_RESET';

export const CONTRIBUTION_LIST_REQUEST = 'CONTRIBUTION_LIST_REQUEST';
export const CONTRIBUTION_LIST_SUCCESS = 'CONTRIBUTION_LIST_SUCCESS';
export const CONTRIBUTION_LIST_FAIL = 'CONTRIBUTION_LIST_FAIL';
export const CONTRIBUTION_LIST_RESET = 'CONTRIBUTION_LIST_RESET';

export const SIGNATURE_VERIFICATION_REQUEST = 'SIGNATURE_VERIFICATION_REQUEST';
export const SIGNATURE_VERIFICATION_SUCCESS = 'SIGNATURE_VERIFICATION_SUCCESS';
export const SIGNATURE_VERIFICATION_FAIL = 'SIGNATURE_VERIFICATION_FAIL';
export const SIGNATURE_VERIFICATION_RESET = 'SIGNATURE_VERIFICATION_RESET';

export const SIGNATURE_REQUEST = 'SIGNATURE_REQUEST';
export const SIGNATURE_SUCCESS = 'SIGNATURE_SUCCESS';
export const SIGNATURE_FAIL = 'SIGNATURE_FAIL';
export const SIGNATURE_RESET = 'SIGNATURE_RESET';

export const GET_MILESTONES_REQUEST = 'GET_MILESTONES_REQUEST';
export const GET_MILESTONES_SUCCESS = 'GET_MILESTONES_SUCCESS';
export const GET_MILESTONES_FAIL = 'GET_MILESTONES_FAIL';

export const CREATE_MILESTONE_REQUEST = 'CREATE_MILESTONE_REQUEST';
export const CREATE_MILESTONE_SUCCESS = 'CREATE_MILESTONE_SUCCESS';
export const CREATE_MILESTONE_FAIL = 'CREATE_MILESTONE_FAIL';

export const DELETE_CONTRIBUTION_REQUEST = ' DELETE_CONTRIBUTION_REQUEST';
export const DELETE_CONTRIBUTION_SUCCESS = ' DELETE_CONTRIBUTION_SUCCESS';
export const DELETE_CONTRIBUTION_FAIL = ' DELETE_CONTRIBUTION_FAIL';
export const DELETE_CONTRIBUTION_RESET = ' DELETE_CONTRIBUTION_RESET';

export const DELETE_SIGNATURE_REQUEST = ' DELETE_SIGNATURE_REQUEST';
export const DELETE_SIGNATURE_SUCCESS = ' DELETE_SIGNATURE_SUCCESS';
export const DELETE_SIGNATURE_FAIL = ' DELETE_SIGNATURE_FAIL';
export const DELETE_SIGNATURE_RESET = ' DELETE_SIGNATURE_RESET';
