export const NEED_RECEIPT_LIST_REQUEST = 'NEED_RECEIPT_LIST_REQUEST';
export const NEED_RECEIPT_LIST_SUCCESS = 'NEED_RECEIPT_LIST_SUCCESS';
export const NEED_RECEIPT_LIST_FAIL = 'NEED_RECEIPT_LIST_FAIL';
export const NEED_RECEIPT_LIST_RESET = 'NEED_RECEIPT_LIST_RESET';

export const DELETE_RECEIPT_REQUEST = 'DELETE_RECEIPT_REQUEST';
export const DELETE_RECEIPT_SUCCESS = 'DELETE_RECEIPT_SUCCESS';
export const DELETE_RECEIPT_FAIL = 'DELETE_RECEIPT_FAIL';
export const DELETE_RECEIPT_RESET = 'DELETE_RECEIPT_RESET';

export const ADD_RECEIPT_REQUEST = 'ADD_RECEIPT_REQUEST';
export const ADD_RECEIPT_SUCCESS = 'ADD_RECEIPT_SUCCESS';
export const ADD_RECEIPT_FAIL = 'ADD_RECEIPT_FAIL';
export const ADD_RECEIPT_RESET = 'ADD_RECEIPT_RESET';
