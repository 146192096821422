export const RESOLVE_COMMENT_REQUEST = 'RESOLVE_COMMENT_REQUEST';
export const RESOLVE_COMMENT_SUCCESS = 'RESOLVE_COMMENT_SUCCESS';
export const RESOLVE_COMMENT_FAIL = 'RESOLVE_COMMENT_FAIL';
export const RESOLVE_COMMENT_RESET = 'RESOLVE_COMMENT_RESET';

export const UPDATE_COMMENT_REQUEST = 'UPDATE_COMMENT_REQUEST';
export const UPDATE_COMMENT_SUCCESS = 'UPDATE_COMMENT_SUCCESS';
export const UPDATE_COMMENT_FAIL = 'UPDATE_COMMENT_FAIL';
export const UPDATE_COMMENT_RESET = 'UPDATE_COMMENT_RESET';

export const DELETE_COMMENT_REQUEST = 'DELETE_COMMENT_REQUEST';
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';
export const DELETE_COMMENT_FAIL = 'DELETE_COMMENT_FAIL';
export const DELETE_COMMENT_RESET = 'DELETE_COMMENT_RESET';

export const LIST_COMMENT_REQUEST = 'LIST_COMMENT_REQUEST';
export const LIST_COMMENT_SUCCESS = 'LIST_COMMENT_SUCCESS';
export const LIST_COMMENT_FAIL = 'LIST_COMMENT_FAIL';
export const LIST_COMMENT_RESET = 'LIST_COMMENT_RESET';

export const CREATE_COMMENT_REQUEST = 'CREATE_COMMENT_REQUEST';
export const CREATE_COMMENT_SUCCESS = 'CREATE_COMMENT_SUCCESS';
export const CREATE_COMMENT_FAIL = 'CREATE_COMMENT_FAIL';
export const CREATE_COMMENT_RESET = 'CREATE_COMMENT_RESET';
