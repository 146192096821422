export const WALLET_NONCE_REQUEST = 'WALLET_NONCE_REQUEST';
export const WALLET_NONCE_SUCCESS = ' WALLET_NONCE_SUCCESS';
export const WALLET_NONCE_FAIL = ' WALLET_NONCE_FAIL';

export const WALLET_VERIFY_REQUEST = 'WALLET_VERIFY_REQUEST';
export const WALLET_VERIFY_SUCCESS = ' WALLET_VERIFY_SUCCESS';
export const WALLET_VERIFY_FAIL = ' WALLET_VERIFY_FAIL';
export const WALLET_VERIFY_RESET = 'WALLET_VERIFY_RESET';

export const GET_ANALYTICS_NGOS_REQUEST = 'GET_ANALYTICS_NGOS_REQUEST';
export const GET_ANALYTICS_NGOS_SUCCESS = ' GET_ANALYTICS_NGOS_SUCCESS';
export const GET_ANALYTICS_NGOS_FAIL = ' GET_ANALYTICS_NGOS_FAIL';

export const GET_ANALYTICS_ECOSYSTEM_CHILDREN_REQUEST = 'GET_ANALYTICS_ECOSYSTEM_CHILDREN_REQUEST';
export const GET_ANALYTICS_ECOSYSTEM_CHILDREN_SUCCESS = ' GET_ANALYTICS_ECOSYSTEM_CHILDREN_SUCCESS';
export const GET_ANALYTICS_ECOSYSTEM_CHILDREN_FAIL = ' GET_ANALYTICS_ECOSYSTEM_CHILDREN_FAIL';

export const GET_FAMILY_ANALYTICS_REQUEST = 'GET_FAMILY_ANALYTICS_REQUEST';
export const GET_FAMILY_ANALYTICS_SUCCESS = ' GET_FAMILY_ANALYTICS_SUCCESS';
export const GET_FAMILY_ANALYTICS_FAIL = ' GET_FAMILY_ANALYTICS_FAIL';

export const GET_ANALYTICS_CONTRIBUTION_REQUEST = 'GET_ANALYTICS_CONTRIBUTION_REQUEST';
export const GET_ANALYTICS_CONTRIBUTION_SUCCESS = ' GET_ANALYTICS_CONTRIBUTION_SUCCESS';
export const GET_ANALYTICS_CONTRIBUTION_FAIL = ' GET_ANALYTICS_CONTRIBUTION_FAIL';

export const GET_ANALYTICS_CHILDREN_REQUEST = 'GET_ANALYTICS_CHILDREN_REQUEST';
export const GET_ANALYTICS_CHILDREN_SUCCESS = ' GET_ANALYTICS_CHILDREN_SUCCESS';
export const GET_ANALYTICS_CHILDREN_FAIL = ' GET_ANALYTICS_CHILDREN_FAIL';

export const GET_ANALYTICS_CHILD_REQUEST = 'GET_ANALYTICS_CHILD_REQUEST';
export const GET_ANALYTICS_CHILD_SUCCESS = ' GET_ANALYTICS_CHILD_SUCCESS';
export const GET_ANALYTICS_CHILD_FAIL = ' GET_ANALYTICS_CHILD_FAIL';

export const GET_ANALYTICS_CHILD_FAMILY_REQUEST = 'GET_ANALYTICS_CHILD_FAMILY_REQUEST';
export const GET_ANALYTICS_CHILD_FAMILY_SUCCESS = 'GET_ANALYTICS_CHILD_FAMILY_SUCCESS';
export const GET_ANALYTICS_CHILD_FAMILY_FAIL = 'GET_ANALYTICS_CHILD_FAMILY_FAIL';

export const GET_ANALYTICS_NEED_REQUEST = 'GET_ANALYTICS_NEED_REQUEST';
export const GET_ANALYTICS_NEED_SUCCESS = 'GET_ANALYTICS_NEED_SUCCESS';
export const GET_ANALYTICS_NEED_FAIL = 'GET_ANALYTICS_NEED_FAIL';
