export const GET_ALL_MIDJOURNEY_IMAGES_REQUEST = 'GET_ALL_MIDJOURNEY_IMAGES_REQUEST';
export const GET_ALL_MIDJOURNEY_IMAGES_SUCCESS = 'GET_ALL_MIDJOURNEY_IMAGES_SUCCESS';
export const GET_ALL_MIDJOURNEY_IMAGES_FAIL = 'GET_ALL_MIDJOURNEY_IMAGES_FAIL';
export const GET_ALL_MIDJOURNEY_IMAGES_RESET = 'GET_ALL_MIDJOURNEY_IMAGES_RESET';

export const DELETE_MIDJOURNEY_FOLDER_REQUEST = 'DELETE_MIDJOURNEY_FOLDER_REQUEST';
export const DELETE_MIDJOURNEY_FOLDER_SUCCESS = 'DELETE_MIDJOURNEY_FOLDER_SUCCESS';
export const DELETE_MIDJOURNEY_FOLDER_FAIL = 'DELETE_MIDJOURNEY_FOLDER_FAIL';

export const SELECT_MIDJOURNEY_IMAGE_REQUEST = 'SELECT_MIDJOURNEY_IMAGE_REQUEST';
export const SELECT_MIDJOURNEY_IMAGE_SUCCESS = 'SELECT_MIDJOURNEY_IMAGE_SUCCESS';
export const SELECT_MIDJOURNEY_IMAGE_FAIL = 'SELECT_MIDJOURNEY_IMAGE_FAIL';
