export const OPEN_TICKETING = 'OPEN_TICKETING';
export const SELECTED_TICKET = 'SELECTED_TICKET';
export const SEARCH_USER = 'SEARCH_USER';

export const ADD_TICKET_REQUEST = 'ADD_TICKET_REQUEST';
export const ADD_TICKET_SUCCESS = 'ADD_TICKET_SUCCESS';
export const ADD_TICKET_FAIL = 'ADD_TICKET_FAIL';
export const ADD_TICKET_RESET = 'ADD_TICKET_RESET';

export const ADD_TICKET_CONTENT_REQUEST = 'ADD_TICKET_CONTENT_REQUEST';
export const ADD_TICKET_CONTENT_SUCCESS = 'ADD_TICKET_CONTENT_SUCCESS';
export const ADD_TICKET_CONTENT_FAIL = 'ADD_TICKET_CONTENT_FAIL';
export const ADD_TICKET_CONTENT_RESET = 'ADD_TICKET_CONTENT_RESET';

export const UPDATE_TICKET_COLOR_REQUEST = 'UPDATE_TICKET_COLOR_REQUEST';
export const UPDATE_TICKET_COLOR_SUCCESS = 'UPDATE_TICKET_COLOR_SUCCESS';
export const UPDATE_TICKET_COLOR_FAIL = 'UPDATE_TICKET_COLOR_FAIL';
export const UPDATE_TICKET_COLOR_RESET = 'UPDATE_TICKET_COLOR_RESET';

export const USER_TICKET_LIST_REQUEST = 'USER_TICKET_LIST_REQUEST';
export const USER_TICKET_LIST_SUCCESS = 'USER_TICKET_LIST_SUCCESS';
export const USER_TICKET_LIST_FAIL = 'USER_TICKET_LIST_FAIL';
export const USER_TICKET_LIST_RESET = 'USER_TICKET_LIST_RESET';

export const TICKET_BY_ID_REQUEST = 'TICKET_BY_ID_REQUEST';
export const TICKET_BY_ID_SUCCESS = 'TICKET_BY_ID_SUCCESS';
export const TICKET_BY_ID_FAIL = 'TICKET_BY_ID_FAIL';
export const TICKET_BY_ID_RESET = 'TICKET_BY_ID_RESET';
