export const SW_DETAILS_REQUEST = 'SW_DETAILS_REQUEST';
export const SW_DETAILS_SUCCESS = 'SW_DETAILS_SUCCESS';
export const SW_DETAILS_FAIL = 'SW_DETAILS_FAIL';
export const SW_DETAILS_RESET = 'SW_DETAILS_RESET';

export const SW_BY_ID_REQUEST = 'SW_BY_ID_REQUEST';
export const SW_BY_ID_SUCCESS = 'SW_BY_ID_SUCCESS';
export const SW_BY_ID_FAIL = 'SW_BY_ID_FAIL';
export const SW_BY_ID_RESET = 'SW_BY_ID_RESET';

export const SW_LIST_REQUEST = 'SW_LIST_REQUEST';
export const SW_LIST_SUCCESS = 'SW_LIST_SUCCESS';
export const SW_LIST_FAIL = 'SW_LIST_FAIL';
export const SW_LIST_RESET = 'SW_LIST_RESET';

export const UPDATE_SW_IS_ACTIVE_REQUEST = 'UPDATE_SW_IS_ACTIVE_REQUEST';
export const UPDATE_SW_IS_ACTIVE_SUCCESS = 'UPDATE_SW_IS_ACTIVE_SUCCESS';
export const UPDATE_SW_IS_ACTIVE_FAIL = 'UPDATE_SW_IS_ACTIVE_FAIL';
export const UPDATE_SW_IS_ACTIVE_RESET = 'UPDATE_SW_IS_ACTIVE_RESET';

export const UPDATE_SW_REQUEST = 'UPDATE_SW_REQUEST';
export const UPDATE_SW_SUCCESS = 'UPDATE_SW_SUCCESS';
export const UPDATE_SW_FAIL = 'UPDATE_SW_FAIL';
export const UPDATE_SW_RESET = 'UPDATE_SW_RESET';

export const ADD_SW_REQUEST = 'ADD_SW_REQUEST';
export const ADD_SW_SUCCESS = 'ADD_SW_SUCCESS';
export const ADD_SW_FAIL = 'ADD_SW_FAIL';
export const ADD_SW_RESET = 'ADD_SW_RESET';

export const SW_CHILD_LIST_REQUEST = 'SW_CHILD_LIST_REQUEST';
export const SW_CHILD_LIST_SUCCESS = 'SW_CHILD_LIST_SUCCESS';
export const SW_CHILD_LIST_FAIL = 'SW_CHILD_LIST_FAIL';
export const SW_CHILD_LIST_RESET = 'SW_CHILD_LIST_RESET';

export const MIGRATE_SW_CHILDREN_REQUEST = 'MIGRATE_SW_CHILDREN_REQUEST';
export const MIGRATE_SW_CHILDREN_SUCCESS = 'MIGRATE_SW_CHILDREN_SUCCESS';
export const MIGRATE_SW_CHILDREN_FAIL = 'MIGRATE_SW_CHILDREN_FAIL';
export const MIGRATE_SW_CHILDREN_RESET = 'MIGRATE_SW_CHILDREN_RESET';

export const MIGRATE_ONE_CHILD_REQUEST = 'MIGRATE_ONE_CHILD_REQUEST';
export const MIGRATE_ONE_CHILD_SUCCESS = 'MIGRATE_ONE_CHILD_SUCCESS';
export const MIGRATE_ONE_CHILD_FAIL = 'MIGRATE_ONE_CHILD_FAIL';
export const MIGRATE_ONE_CHILD_RESET = 'MIGRATE_ONE_CHILD_RESET';

export const DELETE_SW_REQUEST = 'DELETE_SW_REQUEST';
export const DELETE_SW_SUCCESS = 'DELETE_SW_SUCCESS';
export const DELETE_SW_FAIL = 'DELETE_SW_FAIL';
